<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">Select all of the statements below that are true:</p>

      <div v-for="option in options1" :key="'pt-1-' + option.value" class="pl-8">
        <v-checkbox v-model="inputs.input1" hide-details :value="option.value">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question39',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: [],
      },
      options1: [
        {
          text: '$\\ce{BCl3}$ possesses a trigonal planar geometry',
          value: 'a',
        },
        {
          text: 'Trigonal planar molecules contain 3 electron domains surrounding a central atom',
          value: 'b',
        },
        {
          text: 'Molecules with 3 atoms branching off the central atom always have a trigonal planar shape',
          value: 'c',
        },
        {text: '$\\ce{PCl3}$ is trigonal planar in shape', value: 'd'},
      ],
    };
  },
};
</script>
